<template>
  <div id="gleam-block">
    <a class="e-widget no-button generic-loader" :href="raffleLink" rel="nofollow"></a>
  </div>
</template>

<script setup lang="ts">
import loadjs, { isDefined } from 'loadjs';

defineProps({
  raffleLink: {
    type: String,
    default: undefined,
  },
});

onMounted(() => {
  loadScript();
});

function loadScript() {
  if (isDefined('gleamjs-script')) return Promise.resolve(true);
  const loadScript = loadjs('https://widget.gleamjs.io/e.js', 'gleamjs-script', {
    before: (_path: string, el: HTMLElement) => {
      el.setAttribute('id', 'gleamjs-script');
    },
    returnPromise: true,
  });
  return loadScript
    .then(() => {
      return Promise.resolve(true);
    })
    .catch((error: unknown) => {
      console.warn(error); // eslint-disable-line no-console
      return Promise.resolve(false);
    });
}
</script>

<style lang="scss">
.section-wrapper .component-wrapper--gleam-raffle {
  padding-left: 0 !important;
}
</style>
